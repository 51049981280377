import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { feedbackFind, feedbackFormSubmit } from "../../store/feedback/feedbackActions"
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from "reactstrap"
import { QuestionPreview } from "../questions/questionPreview"

export const FormFinal = (props) => {

  const navigate = useNavigate()

  const { Feedback } = useSelector(state => state)
  const { feedback } = Feedback
  const dispatch = useDispatch()

  const [pages, setPages] = useState([])

  const [name, set_name] = useState("")
  const [email, set_email] = useState("")
  const [phone, set_phone] = useState("")
  const [response, setResponse] = useState({})
  const [errors, setErrors] = useState([])
  const [form, setForm] = useState()
  const { id } = useParams()

  const callBack = () => {
    navigate(`/thanks/${id}`)
  }

  const submitForm = () => {
    let e = []
    for (const [key, value] of Object.entries(response)) {
      const question = feedback.feedback_form_questions.filter(row => row.id == key)
      // if(question[0].mandatory === 'Yes' &&  value.response){}else{
      //   e.push(parseInt(key))
      // }
    }
    setErrors(e)
    // if(e.length == 0){
    dispatch(feedbackFormSubmit({ response, feedback_form_id: feedback.id, name, email, phone, callBack }))
    // }
  }

  const get = () => dispatch(feedbackFind(id))

  useEffect(() => {
    feedback && setForm(feedback)
  }, [feedback])

  useEffect(() => get(), [])

  const submit = () => {

  }

  const [active, setActive] = useState(0)

  // const tab = useState()

  useEffect(() => {
  }, [])
  return <React.Fragment>

    <div className="" style={{
      backgroundImage: `url(${feedback?.cover})`,
      backgroundPosition: "center center",
      backgroundRepeat: "repeat",
      backgroundSize: "cover",
      overflow: "hidden",
      marginTop: 0,
      padding: 20
    }}>
      <Container>
        <Card className="mt-5" style={{ backgroundColor: "rgba(255,255,255,0.9)" }}>
          <CardBody className="mt-5 pt-5">
            <Row>
              <Col sm={2}>
                <img className="img-fluid rounded-circle" style={{ height: 100 }} src={feedback?.company_logo} />
              </Col>
              <Col sm={10} style={{ height: 100, justifyContent: "center", alignContent: "center" }}>
                <h3 className="">{feedback?.form_name}</h3>
              </Col>
            </Row>
            <div className="mt-3 ">
              {((active === 0 && feedback?.type === "Wizard") || feedback?.type === 'Single' ) && <div className="p-2 bg-white shadow-sm mb-2">
                {(feedback?.feedback_form_fields || []).map((row, index) => <div
                  key={`question-${row.id}`}>

                  <FormGroup>
                    <Label>{row.label}</Label>
                    {row.label === "Full Names" &&
                      <Input style={{ borderRadius: 15 }} className="form-control-lg" value={name}
                             onChange={e => set_name(e.target.value)} placeholder={row.placeholder} />}
                    {row.label === "Email Address" &&
                      <Input style={{ borderRadius: 15 }} className="form-control-lg" value={email}
                             onChange={e => set_email(e.target.value)} placeholder={row.placeholder} />}
                    {row.label === "Phone" &&
                      <Input style={{ borderRadius: 15 }} className="form-control-lg" value={phone}
                             onChange={e => set_phone(e.target.value)} placeholder={row.placeholder} />}
                  </FormGroup>
                </div>)}
              </div>}
              {(feedback.feedback_form_questions || []).map((row, index) => (index === active - 1 || feedback.type === "Single") &&
                <div key={`question-${row.id}`}
                     className="list-group-item">
                  {errors.includes(row.id) && <small className="text-danger">Please fill in this field</small>}

                  <QuestionPreview question={row} isFeedback appendResponse={res => {
                    let data = response
                    Object.assign(data, res)
                    setResponse(data)
                  }} />

                </div>)}
              {feedback?.type === 'Wizard' && ((feedback?.feedback_form_questions || []).length + 1) === active &&  <p style={{ textAlign: "center" }} className="m-5 p-5 font-size-16 text-muted"><strong>By completing
                this survey you are consenting to storing and using your data to help us improve our services to
                you</strong></p>}
              {feedback?.type === "Wizard" && ((feedback?.feedback_form_questions || []).length + 1) !== active   && <Row className="mt-5">
                <Col xs={4} sm={4}>
                  {active > 0 && <Button onClick={() => setActive(active - 1)} className="btn btn-rounded btn-light btn-lg">
                    <i className="mdi mdi-arrow-left" /> Prev
                  </Button>}
                </Col>
                <Col xs={4} sm={4}>
                  <div className="text-center mt-2">{active + 1} / {((feedback?.feedback_form_questions || []).length)  + 2}</div>
                </Col>
                <Col xs={4} sm={4}>
                  {active <= (feedback?.feedback_form_questions || []).length &&  <Button onClick={() => setActive(active + 1)} className="btn btn-rounded float-end btn-dark btn-lg">
                    Next <i className="mdi mdi-arrow-right" />
                  </Button>}
                </Col>



              </Row>}
             <div className="text-center">
               {feedback?.type === 'Wizard' && ((feedback?.feedback_form_questions || []).length + 1) === active  && <Button
                 onClick={() => submitForm()}
                 className="btn btn-rounded btn-dark btn-lg">Submit Feedback <i
                 className="mdi mdi-send-outline"></i>
               </Button>}
             </div>
              {/*<div>*/}
              {/*  {[0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(row => row == 3 ? <span key={row} style={{*/}
              {/*    display: "inline-block",*/}
              {/*    width: "8.8%",*/}
              {/*    borderRadius: "10px",*/}
              {/*    marginRight: "0.1%",*/}
              {/*    textAlign: "center",*/}
              {/*    paddingTop: "10px",*/}
              {/*    paddingBottom: "10px",*/}
              {/*    backgroundColor:"#0CA843"*/}
              {/*  }}><i className="mdi mdi-star text-warning font-size-14 "></i></span> : <Link style={{*/}
              {/*    display: "inline-block",*/}
              {/*    width: "8.8%",*/}
              {/*    borderRadius: "10px",*/}
              {/*    marginRight: "0.1%",*/}
              {/*    backgroundColor: "#e9e9e9",*/}
              {/*    textAlign: "center",*/}
              {/*    paddingTop: "10px",*/}
              {/*    paddingBottom: "10px"*/}
              {/*  }} key={row} onClick={() => {*/}
              {/*  }}><strong style={{ color: "black" }}>{row}</strong></Link>)}*/}
              {/*</div>*/}
              {/*<div style={{ marginTop: 60 }}>*/}
              {/*  <FormGroup style={{ marginBottom: -30 }}>*/}
              {/*    <Label htmlFor="customRange1" className="form-label">Example Range</Label>*/}
              {/*    <Input type="range" style={{}} className="form-range" id="customRange1" />*/}
              {/*  </FormGroup>*/}
              {/*  <div style={{ borderRadius: "30px" }}>*/}
              {/*    <div className="bg-danger" style={{*/}
              {/*      float: "left",*/}
              {/*      paddingTop: "10px",*/}
              {/*      paddingBottom: "10px",*/}
              {/*      width: "20%"*/}
              {/*    }}>*/}
              {/*    </div>*/}
              {/*    <div className="bg-warning" style={{*/}
              {/*      float: "left",*/}
              {/*      paddingTop: "10px",*/}
              {/*      paddingBottom: "10px",*/}
              {/*      width: "20%"*/}
              {/*    }}>*/}
              {/*    </div>*/}
              {/*    <div className="bg-success" style={{*/}
              {/*      float: "left",*/}
              {/*      paddingTop: "10px",*/}
              {/*      paddingBottom: "10px",*/}
              {/*      width: "20%"*/}
              {/*    }}>*/}
              {/*    </div>*/}
              {/*    <div className="bg-info" style={{*/}
              {/*      float: "left",*/}
              {/*      paddingTop: "10px",*/}
              {/*      paddingBottom: "10px",*/}
              {/*      width: "20%"*/}
              {/*    }}>*/}
              {/*    </div>*/}
              {/*    <div className="bg-primary" style={{*/}
              {/*      float: "left",*/}
              {/*      paddingTop: "10px",*/}
              {/*      paddingBottom: "10px",*/}
              {/*      width: "20%"*/}
              {/*    }}>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <CardBody>
              {feedback?.type === 'Single' && <p style={{ textAlign: "center" }} className="mt-5 mb-5 font-size-16 text-muted"><strong>By completing
                this survey you are consenting to storing and using your data to help us improve our services to
                you</strong></p>}
              <div className="text-center">
                {/*<Button className="btn btn-soft-dark btn-lg me-1"*/}
                {/*        type="reset"*/}
                {/*>Reset</Button>*/}
                {feedback?.type === 'Single' && <Button
                  onClick={() => submitForm()}
                  className="btn btn-rounded btn-dark btn-lg">Submit Feedback <i
                  className="mdi mdi-send-outline"></i>
                </Button>}
                {/*<Button outline className="btn-soft-primary" onClick={() => submitForm()} >Submit Form</Button>*/}
              </div>
            </CardBody>

          </CardBody>
          {/* <Pre data={feedback} /> */}
        </Card>
      </Container>
    </div>

  </React.Fragment>
}